import $ from 'jquery';
import AOS from 'aos';

$(document).ready(function() {

    /* MATCH HEIGHT EQUAL HEIGHT

    $('.matchHeight').matchHeight();
     */

    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */

});